body, html {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.portfolio-header {
  position: relative;
  background: #fff;
  background-image: url(../images/header-bg.jpg);
}

.portfolio-header .mdl-layout__header-row {
  padding: 0;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.mdl-layout__title {
  font-size: 24px;
  text-align: center;
  font-weight: 300;
}

.is-compact .mdl-layout__title span {
  display: none;
}

.portfolio-logo-row {
  min-height: 200px;
}

.is-compact .portfolio-logo-row {
  min-height: auto;
}

.portfolio-logo {
  background: url(../images/wcc-logo.png) 100% no-repeat;
  background-size: cover;
  height: 150px;
  width: 150px;
  margin: auto auto 10px;
  margin-bottom: 0px;
}

.negative-margin { 
  margin-top: -10px;
}

.is-compact .portfolio-logo {
  height: 50px;
  width: 50px;
  margin-top: 7px;
}

.portfolio-navigation-row {
  background-color: rgba(0, 0, 0, 0.08);
  text-transform: uppercase;
  height: 45px;
}

.portfolio-navigation-row  .mdl-navigation {
  text-align: center;
  max-width: 900px;
  width: 100%;
}

.portfolio-navigation-row .mdl-navigation__link {
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  line-height: 42px;
}

.portfolio-header .mdl-layout__drawer-button {
    background-color: rgba(197, 197, 197, 0.44);
}

.portfolio-navigation-row .is-active {
  position: relative;
  font-weight: bold;
}

.portfolio-navigation-row .is-active:focus {
  outline-width: 0;
}

.portfolio-navigation-row .is-active:after {
  content: "";
  width: 70%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #2e7d32;
  left: 15%;
}

.portfolio-card .mdl-card__title {
  padding-bottom: 0;
}

.portfolio-blog-card-compact .mdl-card__title {
  padding-bottom: 0;
}

.portfolio-blog-card-bg > .mdl-card__actions {
  height: 52px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.2);
}

img.article-image {
  width: 100%;
  height: auto;
}

.portfolio-max-width {
  max-width: 900px;
  margin: auto;
}

.portfolio-copy {
  max-width: 700px;
}

.no-padding {
  padding: 0;
}

.no-left-padding{
  padding-left: 0;
}

.no-bottom-padding {
  padding-bottom: 0;
}

.padding-top {
  padding: 10px 0 0;
}

.portfolio-share-btn {
  position: relative;
  float: right;
  top: -4px;
}

.demo-card-event > .mdl-card__actions {
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.portfolio-contact .mdl-textfield {
  width: 100%;
}

.portfolio-contact form {
  max-width: 550px;
  margin: auto;
}

footer {
  background-image: url(../images/footer-background.png);
  background-size: cover;
}

.card-date {
  font-size: 36px;
  line-height: 36px;
  width: 100%;
  text-align: left;
  padding: 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 300;
}

.mdl-card__media.green {
  background-color: #c8e6c9;
}

.mdl-card__supporting-text a{
  color: #039be5;
  text-decoration: none;
}


.mdl-card__supporting-text a:hover{
  text-decoration: underline;
}

.mdl-button--accent.mdl-button--accent.mdl-button--raised[disabled][disabled] {
  background-color: rgba(0,0,0,.12);
  color: rgba(0,0,0,.26);
  box-shadow: none;
}

.mdl-button--accent.mdl-button--accent.mdl-button--raised.green {
  background: #4caf50;
}

.mdl-button--accent.mdl-button--accent.mdl-button--raised.white {
  background: #fff;
  color: #4caf50;
}

.mdl-cell.mdl-cell--12-col.mdl-shadow--4dp.dismiss-banner {
  margin-top: 0;
  margin-bottom: 0;
  background: #c8e6c9;
  text-align: center;
}

.mdl-cell.mdl-cell--12-col.mdl-shadow--4dp.dismiss-banner span {
  padding: 8px;
  color: #000;
}

.mdl-cell.mdl-cell--12-col.mdl-shadow--4dp.dismiss-banner span a,
.card-clickable {
  color: #039be5;
  text-decoration: none;
  cursor: pointer;
}

.mdl-cell.mdl-cell--12-col.mdl-shadow--4dp.dismiss-banner span a:hover,
.card-clickable:hover {
  text-decoration: underline;
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-title {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: rgba(0, 0, 0, 0.87);;
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-body,
.mdl-cell.mdl-cell--12-col.mdl-card .simul-body {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-body .simul-heavy,
.mdl-cell.mdl-cell--8-col.mdl-card .simul-details .simul-heavy {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-details .simul-details-item {
  padding-bottom: 12px;
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-details {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-details .simul-uppercase,
.mdl-cell.mdl-cell--12-col.mdl-card .simul-details .simul-uppercase {
  text-transform: uppercase;
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-dismiss,
.mdl-cell.mdl-cell--12-col.mdl-card .simul-dismiss {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  font-size: 11px;
  color: #039be5;
  display: none;
}

.mdl-cell.mdl-cell--8-col.mdl-card:hover .simul-dismiss,
.mdl-cell.mdl-cell--12-col.mdl-card:hover .simul-dismiss {
  display: block;
}

.mdl-cell.mdl-cell--8-col.mdl-card .simul-dismiss:hover,
.mdl-cell.mdl-cell--12-col.mdl-card .simul-dismiss:hover {
  text-decoration: underline;
}

.mdl-card__media {
  background: #fff;
}

.midway-popup img {
  max-width: 100%;
  max-height: 100%;
}

.tourney-popup img {
  max-width: 97%;
  max-height: 100%;
}

.mdl-layout__header {
  color: rgb(66, 66, 66);
}

.mdl-layout__header-row .mdl-navigation__link {
  color: rgb(66, 66, 66);
}

.submit-message {
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  border-radius: 4px;
  font-size: 16px;
}

.submit-message.good {
  background: #c8e6c9;
}

.submit-message.bad {
  background: #ef9a9a;
}

.text-heavy {
  font-weight: 500;
}

.game-header {
  background: rgba(0, 0, 0, 0.08);
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.game-header .game-label {
  line-height: 40px;
  font-size: 24px;
}

.game-header .game-toggle {
  float: right;
  line-height: 40px;
}

.game-header .game-toggle i {
  font-size: 32px;
  line-height: 40px;
}

.tourney-popup .tournament-name,
.thursday-popup .tournament-name {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  padding: 8px;
  text-decoration: underline;
}

.thursday-popup .tournament-name {
  line-height: 30px;
}

.thursday-popup .tournament-description,
.thursday-popup .tournament-dates,
.thursday-popup .tournament-note {
  padding: 16px;
}

.thursday-popup .tournament-note {
  font-size: 13px;
  padding-top: 0;
}

.thursday-popup .tournament-info {
  margin: 0;
  padding-right: 16px;
}

.thursday-popup .tournament-dates .tournament-info {
  margin: 0;
  margin-left: -16px;
}

.tourney-popup .tournament-date {
  text-align: center;
  font-size: 20px;
  padding: 4px;
}

.tourney-popup .tournament-location {
  text-align: center;
  font-weight: 500;
  padding: 4px;
  font-size: 18px;
}

.tourney-popup .tournament-address {
  text-align: center;
  padding: 4px;
  font-size: 18px;
}

.tourney-popup .tournament-sections {
  font-size: 20px;
  padding: 4px 24px;
  font-weight: 500;
  margin-top: 8px;
}

.tourney-popup .tournament-rounds {
  padding: 4px 24px;
  font-size: 18px;
  margin-bottom: 8px;
}

.tourney-popup .bold-label {
  font-weight: 500;
  padding: 4px 24px;
  font-size: 18px;
}

.tourney-popup .tournament-entry-fee {
  padding: 4px 40px;
  font-size: 16px;
}

.tourney-popup .tournament-entry-fee .entry-fee-dollar {
  font-weight: 500;
  text-decoration: underline;
}

.tourney-popup .tournament-prize {
  padding: 4px 24px;
  margin-top: 8px;
  font-weight: 500;
  font-size: 20px;
}

.tourney-popup .us-chess-membership {
  margin-top: 16px;
  padding: 4px 24px;
}

.tourney-popup .us-chess-membership .bold-label,
.tourney-popup .contact-info .bold-label {
  padding: 0;
  margin-top: 8px;
  font-size: 16px;
}

.tourney-popup .contact-info {
  padding: 4px 24px;
}

.tourney-popup .contact-info a {
  color: #039be5;
}

.tourney-popup .contact-info div {
  padding: 4px 0px;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.fit-image img{
  max-height: 100%;
  max-width: 100%;
}

.expiration-date.invalid {
  color: #F44336;
  text-decoration: line-through;
}